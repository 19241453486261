import React from 'react';
import CartIconImage from '../../../assets/images/cart-icon.svg';
import {
  CategoriesHeader,
  BasketCount,
  IconButton,
  SecondaryButton,
  LoyaltyBanner,
} from '@fingermarkglobal/cringer.components';
import ArrowRight from 'heroicons/solid/arrow-right.svg';

const Header = ({
  title = null,
  total = null,
  previousText = 'Back',
  previousAction = null,
  allowPreviousAction = true,
  className = '',
  count = 0,
  showBasketCount = true,
  showCategoriesHeader = false,
  onBasketClick = () => {},
  showMenuAction = true,
  menuText = 'Menu',
  menuAction = () => {},
  basketText = null,
  currentRoute = '',
  showSubtotal = true,
  couponText = 'Enter offer',
  isCouponEnabled = false,
  showCouponButton = false,
  couponAction = () => {},
  computedAction = () => {},
  showLoyaltyBanner = false,
} = {}) => {
  const showBasketText = !currentRoute.match('/cart');

  return (
    <div data-test="header" className={`flex flex-col ${className}`}>
      <div
        className={`${
          showCategoriesHeader ? `py-2 space-y-2` : `py-2 md:py-14 space-y-2 md:space-y-8`
        }`}
      >
        {showCategoriesHeader && <CategoriesHeader />}
        {showLoyaltyBanner && <LoyaltyBanner computedAction={computedAction} />}
        <div className="px-2 text-2xl font-bold uppercase md:px-8 sm:text-5xl md:text-7xl">
          {title}
        </div>
      </div>
      <div className="flex items-center justify-between p-2 px-4 bg-accessory md:px-8 md:py-6">
        <div className="flex justify-center">
          {showMenuAction && (
            <SecondaryButton className="header-button" onClick={menuAction} data-test="menu-button">
              {menuText}
            </SecondaryButton>
          )}
          {!!previousAction && (
            <IconButton
              disabled={!allowPreviousAction}
              onClick={previousAction}
              data-test="back-icon-button"
            >
              {previousText}
            </IconButton>
          )}
        </div>
        <div className="flex items-center">
          {showBasketCount && (
            <div className="flex flex-col justify-center sm:flex-row">
              {showBasketText && (
                <IconButton
                  className="hidden md:block"
                  onClick={onBasketClick}
                  iconReverse={true}
                  Icon={ArrowRight}
                  data-test="view-order-icon-button"
                >
                  {basketText}
                </IconButton>
              )}
              <div className="flex flex-col items-center justify-center sm:flex-row">
                <BasketCount count={count} onClick={onBasketClick} Icon={CartIconImage} />
                {showSubtotal && (
                  <div
                    className="ml-0 text-base font-bold sm:text-2xl md:text-4xl sm:ml-4 md:ml-8"
                    data-test="header-cart-subtotal"
                  >
                    {total}
                  </div>
                )}
              </div>
            </div>
          )}
          {isCouponEnabled && showCouponButton && (
            <SecondaryButton
              className="ml-4 text-xl font-bold md:text-4xl md:ml-8 header-button"
              onClick={couponAction}
              data-test="enter-offer-button"
            >
              {couponText}
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
