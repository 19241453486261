/* eslint-disable max-lines */
// Routes is for the applications workflow definitions.
import React from 'react';
import Logo from './src/assets/images/logo.svg';

import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';

// Custom views
import { CustomHomeView } from './src/views/home';
import { CustomBeaconView } from './src/views/beacon';
import { CustomReceiptView } from './src/views/receipt';

// Custom templates
import { CustomDefaultTemplate } from './src/templates/default';

// Generic
import {
  RootViewProvider,
  RestaurantHomeViewProvider,
  RestaurantBeaconViewProvider,
  RestaurantCouponViewProvider,
  RestaurantCouponView,
  RestaurantCategoriesViewProvider,
  RestaurantCategoryViewProvider,
  RestaurantCategoryView,
  RestaurantCartView,
  RestaurantCartViewProvider,
  RestaurantCategoriesView,
  RestaurantOrderValidationView,
  RestaurantOrderValidationViewProvider,
  RestaurantOrderConfirmationView,
  RestaurantOrderConfirmationViewProvider,
  RestaurantLockViewProvider,
  RestaurantLockView,
  AdminViewProvider,
  AdminView,
  AdminAuthenticateProvider,
  AdminAuthenticateView,
  ResetViewProvider,
  ResetView,
  RestaurantProductComboViewProvider,
  RestaurantProductComboView,
  RestaurantProductComposedViewProvider,
  RestaurantProductComposedView,
  PaymentOptionsView,
  PaymentOptionsViewProvider,
  CardPaymentView,
  CardPaymentViewProvider,
  RefundViewProvider,
  RefundView,
  ReceiptViewProvider,
  NotificationProvider,
  NotificationView,
  SmsViewProvider,
  SmsView,
  InvalidRouteViewProvider,
  InvalidRouteView,
  TransactionsView,
  TransactionsViewProvider,
  LoyaltyLoginView,
  LoyaltyLoginViewProvider,
  LoyaltyOffersView,
  LoyaltyOffersViewProvider,
} from '@fingermarkglobal/cringer.components';

import { Loading } from '@fingermarkglobal/kfc-styles';

import { version } from './package.json';
import { Warning } from './src/components/stateless/warning';
import mockSettings from './mock-settings.json';

const Routes = () => {
  return (
    <Router>
      <RootViewProvider
        ErrorComponent={Warning}
        overwriteSettings={mockSettings}
        resolverProps={{ Loading, isRetryActive: true }}
      >
        <Switch>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/reset">
            <ResetViewProvider cancelOrder={true}>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
              >
                <ResetView />
              </CustomDefaultTemplate>
            </ResetViewProvider>
          </Route>
          <Route exact path="/home">
            <RestaurantHomeViewProvider allowedLanguages={['en', 'uae']}>
              <CustomDefaultTemplate
                footerEnabled={false}
                headerEnabled={false}
                timeoutEnabled={false}
              >
                <CustomHomeView isShowingCustomBackground={true} />
              </CustomDefaultTemplate>
            </RestaurantHomeViewProvider>
          </Route>
          <Route exact path="/beacon">
            <RestaurantBeaconViewProvider primaryPath="/categories" secondaryPath="/reset">
              <CustomDefaultTemplate headerEnabled={false}>
                <CustomBeaconView />
              </CustomDefaultTemplate>
            </RestaurantBeaconViewProvider>
          </Route>
          <Route exact path="/coupon">
            <RestaurantCouponViewProvider>
              <CustomDefaultTemplate>
                <RestaurantCouponView />
              </CustomDefaultTemplate>
            </RestaurantCouponViewProvider>
          </Route>
          <Route exact path="/categories">
            <RestaurantCategoriesViewProvider nextPath="/validate">
              <CustomDefaultTemplate>
                <RestaurantCategoriesView />
              </CustomDefaultTemplate>
            </RestaurantCategoriesViewProvider>
          </Route>
          <Route exact path="/category/:id">
            <RestaurantCategoryViewProvider
              showCategoriesHeader={true}
              displayDescription={false}
              nextPath="/validate"
            >
              <CustomDefaultTemplate>
                <RestaurantCategoryView />
              </CustomDefaultTemplate>
            </RestaurantCategoryViewProvider>
          </Route>
          <Route
            exact
            path={[
              '/product/:productId/combo/:filterId',
              '/product/:productId/combo/:filterId/cart/:uid',
            ]}
          >
            <RestaurantProductComboViewProvider
              nextPath="/validate"
              basketCountPath="/validate"
              showCategoriesHeader={true}
            >
              <CustomDefaultTemplate>
                <RestaurantProductComboView />
              </CustomDefaultTemplate>
            </RestaurantProductComboViewProvider>
          </Route>
          <Route exact path="/product/:productId/composed">
            <RestaurantProductComposedViewProvider nextPath="/validate" basketCountPath="/validate">
              <CustomDefaultTemplate showCategoriesHeader={true}>
                <RestaurantProductComposedView />
              </CustomDefaultTemplate>
            </RestaurantProductComposedViewProvider>
          </Route>
          <Route exact path="/cart">
            <RestaurantCartViewProvider showCategoriesHeader={true} upsellEnabled={true}>
              <CustomDefaultTemplate>
                <RestaurantCartView />
              </CustomDefaultTemplate>
            </RestaurantCartViewProvider>
          </Route>
          <Route exact path="/validate">
            <RestaurantOrderValidationViewProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
                templateOverride={true}
              >
                <RestaurantOrderValidationView
                  image={<Logo data-test="logo" className="absolute top-0 w-auto h-64 mt-32" />}
                  isLogoEnabled={true}
                />
              </CustomDefaultTemplate>
            </RestaurantOrderValidationViewProvider>
          </Route>
          <Route exact path="/payment-options">
            <PaymentOptionsViewProvider>
              <CustomDefaultTemplate footerEnabled={true} headerEnabled={false}>
                <PaymentOptionsView />
              </CustomDefaultTemplate>
            </PaymentOptionsViewProvider>
          </Route>
          <Route exact path="/payment">
            <CardPaymentViewProvider>
              <CustomDefaultTemplate
                timeoutEnabled={false}
                headerEnabled={false}
                footerEnabled={false}
                templateOverride={true}
              >
                <CardPaymentView />
              </CustomDefaultTemplate>
            </CardPaymentViewProvider>
          </Route>
          <Route exact path="/refund">
            <RefundViewProvider nextPath="/reset">
              <CustomDefaultTemplate
                timeoutEnabled={false}
                headerEnabled={false}
                footerEnabled={false}
                templateOverride={true}
              >
                <RefundView />
              </CustomDefaultTemplate>
            </RefundViewProvider>
          </Route>
          <Route exact path="/checkout">
            <RestaurantOrderConfirmationViewProvider nextPath="/receipt">
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
                templateOverride={true}
              >
                <RestaurantOrderConfirmationView
                  image={<Logo data-test="logo" className="absolute top-0 w-auto h-64 mt-32" />}
                  isLogoEnabled={true}
                />
              </CustomDefaultTemplate>
            </RestaurantOrderConfirmationViewProvider>
          </Route>
          <Route exact path="/receipt">
            <ReceiptViewProvider>
              <CustomDefaultTemplate headerEnabled={false} footerEnabled={true}>
                <CustomReceiptView />
              </CustomDefaultTemplate>
            </ReceiptViewProvider>
          </Route>
          <Route exact path="/admin">
            <AdminViewProvider version={version}>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                accessibilityActionEnabled={false}
              >
                <AdminView />
              </CustomDefaultTemplate>
            </AdminViewProvider>
          </Route>
          <Route exact path="/notify">
            <NotificationProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                timeoutEnabled={false}
              >
                <NotificationView />
              </CustomDefaultTemplate>
            </NotificationProvider>
          </Route>
          <Route exact path="/auth">
            <AdminAuthenticateProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                accessibilityActionEnabled={false}
              >
                <AdminAuthenticateView />
              </CustomDefaultTemplate>
            </AdminAuthenticateProvider>
          </Route>
          <Route exact path="/lock">
            <RestaurantLockViewProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                accessibilityActionEnabled={false}
                timeoutEnabled={false}
              >
                <RestaurantLockView />
              </CustomDefaultTemplate>
            </RestaurantLockViewProvider>
          </Route>
          <SmsViewProvider exact path="/sms">
            <CustomDefaultTemplate headerEnabled={false} footerEnabled={false}>
              <SmsView />
            </CustomDefaultTemplate>
          </SmsViewProvider>
          <Route exact path="/transactions">
            <TransactionsViewProvider>
              <CustomDefaultTemplate
                headerEnabled={false}
                footerEnabled={false}
                accessibilityActionEnabled={false}
              >
                <TransactionsView />
              </CustomDefaultTemplate>
            </TransactionsViewProvider>
          </Route>
          <Route exact path="/loyalty-login">
            <LoyaltyLoginViewProvider>
              <CustomDefaultTemplate headerEnabled={true} footerEnabled={true}>
                <LoyaltyLoginView />
              </CustomDefaultTemplate>
            </LoyaltyLoginViewProvider>
          </Route>
          <Route exact path="/loyalty-offers">
            <LoyaltyOffersViewProvider nextPath="/validate">
              <CustomDefaultTemplate headerEnabled={true} footerEnabled={true}>
                <LoyaltyOffersView />
              </CustomDefaultTemplate>
            </LoyaltyOffersViewProvider>
          </Route>
          {/* invalid route should be the last one. all new routes should be placed above ↑ */}
          <Route>
            <InvalidRouteViewProvider>
              <InvalidRouteView />
            </InvalidRouteViewProvider>
          </Route>
        </Switch>
      </RootViewProvider>
    </Router>
  );
};

export { Routes };
